import { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import "./styles.scss";
import Landing from "./Pages/StartPage/Landing";
import SetBorrowerPassword from "./Pages/SetPassword/SetBorrowerPassword";
import Sales from "./Sales/Sales";
import SelectDealer from "./Templates/SelectDealer/SelectDealer";
import Thankyou from "./Pages/Thankyou/Thankyou";
import OnboardingGuard from "./Components/OnboardingGuard/OnboardingGuard";
import Toast from "./Components/Toaster/Toast";
import CoBorrowerOnboarding from "./Sales/CoBorrowerOnboarding";
import { Get } from "./common_var/httpService";
import { useStateValue } from "./Context/StateProvider";
import BankLookupContainer from "./Templates/bank-lookup-container/bank-lookup-container";
// import { PersonaInt } from "./Components/Onboarding-Services/Persona";
import SaltedgeConnect from "./Pages/Saltedge/SaltedgeConnect"

function App() {
  const queryParams = useLocation();
  const [{ currency }, dispatch] = useStateValue();


  const  getCurrency = async () => {
    await Get('api/currency','admin')
          .then((res)=>{
              dispatch({
                type: 'SET_CURRENCY',
                payload: res?.currency?.symbol,
                });

          })
           .catch((err) => {
            console.log(err);
          });
  }
  useEffect(() => {
    if (queryParams?.pathname.includes("onboarding")) {
      document.body.classList.add("landing-background");
    } else {
      document.body.classList.remove("landing-background");
    }
    getCurrency()
  }, []);

  const routes = useRoutes([
    // { path: "/setpassword/:token/:id", element: <SetBorrowerPassword /> },
    { path: "/", element: <Landing /> },
    { path: "/thankyou", element: <Thankyou /> },
    {path: "/onboarding/bank-lookup/:loanid/:token", element: <BankLookupContainer />},
    { path: "/:providername/onboarding/:loanid/:token", element: <Sales /> },
    { path: "/onboarding/:loanid/:token", element: <Sales /> },
    // { path: "/cosignor/onboarding/:loanid/:token", element: <CoBorrowerOnboarding /> },
    { path: "/cosignor/onboarding/:loanid/:token", element: <Sales /> },
    { path: "/full-view/:loanid", element: <Sales /> },
    // { path: "/persona", element: <PersonaInt /> },

    { path:"/saltwaiting",element:<SaltedgeConnect/>}
    // {
    //   element: <OnboardingGuard />,
    //   children: [
    //     // { path: "/selectprovider", element: <SelectDealer /> },
    //     { path: "/:providername/onboarding/:loanid/:token", element: <Sales /> },
    //     { path: "/onboarding/:loanid/:token", element: <Sales /> },
    //   ],
    // },
  ]);
  return (
    <>
      <Toast />
      {routes}
    </>
  );
}

export default App;
