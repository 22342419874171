import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthPost } from "../../common_var/httpService";

const SubmitApplication = ({ type, value, step }) => {
  let loan_id = sessionStorage.getItem("loan_id");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    let values = { loan_id: loan_id };
    e.preventDefault();
    try {
      let res = await AuthPost(`loan/submit-application`, values, "onboarding");
      if (res.statusCode == 200) {
        navigate(`/thankyou`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button
        type={type}
        onClick={(e) => handleSubmit(e)}
        className="MainButton btn_Primary mr_8 mb15"
      >
        {value}
      </button>
    </div>
  );
};

export default SubmitApplication;
