import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";

const TextValidator = ({
  name,
  value,
  isTouched,
  labelName,
  maxLen,
  required,
}) => {
  const [{ formData, formSubmitted, requiredFields }, dispatch] = useStateValue();

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if ((typeof value == "string") ? !value?.trim() && required : !value && required) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      }
      //  else if (
      //   value?.length < 5 &&
      //   (name === "zipcode" || name === "coSignor_zipcode")
      // ) {
      //   dispatch({
      //     type: "SET_ERRORS",
      //     payload: { [name]: `should have ${5} characters` },
      //   });
      // }
       else if (
        (name == "duration" && Number(value) <= 1) ||
        (name == "coSignor_duration" && Number(value) <= 1)
      ) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `should be greater 1 month` },
        });
      } else if (
        name === "monthly_income" ||
        name === "annual_income" ||
        name === "additional_income" ||
        name === "monthly_mortgage" ||
        name === 'coSignor_monthly_income' ||
        name === 'coSignor_annual_income' ||
        name === 'coSignor_additional_income' ||
        name === 'coSignor_monthly_mortgage' 
      ) {
        if (value < 0) {
          dispatch({
            type: "SET_ERRORS",
            payload: { [name]: "Invalid" },
          });
        }
        else {
          dispatch({
            type: "REMOVE_ERROR",
            payload: name,
          });
        }
      }
       else {
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);

  useEffect(() => {
    dispatch({
      type: 'SET_VALUES',
      payload: {[name]: value ?? null}
    })

    if(required) {
      const isExist = requiredFields?.find(item => item.name === name);
      !isExist && dispatch({
        type: "SET_REQUIRED_FIELDS",
        payload: { name, Fieldtype: "text" } 
      })
    }
  }, [name]);

  return <></>;
};

export default TextValidator;
