import React, { useState, useRef, useEffect } from "react";
import {
  useRoutes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SaltedgeConnect from "../../Pages/Saltedge/SaltedgeConnect";
import SkipButton from "../SkipButton/SkipButton";
import Button from "../Button/Button";
import BanksImage from "../../assets/images/bank.png";
import Image from "../Image/Image";
function Saltedge({ step, skip, goNextStep }) {
  //need to add cus id in link valid table to check the its valid or the bank is already connected

  const queryParams = useLocation();
  const pathname = queryParams.pathname;
  const { loanid } = useParams();
  const [url, setUrl] = useState("");
  const [searchParams] = useSearchParams();
  console.log("pathname---->", queryParams.pathname);
  const [connectionid, setConnectionid] = useState(false);
  const [connectbtn, setConnectbtn] = useState(false);
  const [frame, setFrame] = useState(false);
  const [cancelsend,setCancelsend]=useState(false)
  const elem = useRef(null);
  const [{ frames, currentStep, isCosignorFlow, globalStep }, dispatch] =
    useStateValue();
  console.log(pathname.split("/onboarding/")[1]);
  const urlforsalt = window.location.href;
  // we got everything now just write the get bankn detail call thats it and see why not url getting set
  const pramstring = searchParams.get("connection_id");

  const checkbank = async (savecall) => {
    console.log("id--->", connectionid);

    await AuthGet(`api/saltcheckdata/${loanid}`, "onboarding")
      .then((res) => {
        console.log("res salt---->", res.data, res.cusdata);
        if (res.message) {
          setConnectionid(true);
          sessionStorage.setItem("connectionid", res.connectionid);
        }
        if(savecall=='vialink'){
          setCancelsend(true)
          setTimeout(()=>{
            window.open(process.env.REACT_APP_UI_URL+"/borrower/login","_self")
               },5000)
               return;
        } 
        if (res.data.length > 0 && !savecall=="savedata") {
          setFrame(false);
          setConnectbtn(false);
        } else {
          console.log(pramstring)
          if(pramstring || JSON.parse(sessionStorage.getItem("connectionid"))){
             closeFrame()
          }
          setConnectbtn(true);
        }
      })
      .catch((err) => {
        console.log("err salt---->", err);
      });
  };

  const send = async () => {


    await AuthPost(
      `api/saltgetdata/${loanid}`,
      { url: urlforsalt },
      "onboarding"
    )
      .then((res) => {
        setFrame(true);
        setUrl(res.data);
        window.open(res.data, "_self");
      })
      .catch((err) => {
        console.log("err salt---->", err);
      });
  };

  useEffect(() => {
    checkbank();
  }, []);

  useEffect(() => {
    if (pramstring) {
      sessionStorage.setItem("connectionid", pramstring);
    }
    const conget = JSON.parse(sessionStorage.getItem("connectionid"));

    if (conget) {
      setConnectionid(true);
    }

    if (pramstring || conget) {
      let paramkey;
      if (pramstring) {
        paramkey = pramstring;
      } else if (conget) {
        paramkey = conget;
      }
    }
  }, []);

  const createConn = async (conget) => {
    await AuthGet(`api/savesaltconid/${conget}/${loanid}`, "onboarding")
      .then((res) => {
        console.log("res---->", res);
        if (res.statusCode === 200) {
          // toast.success("bank connected")
          stepupdate();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
console.log('loanid::: ', loanid);
  let stepupdate = async () => {
    let values = {
      loan_id: loanid,
      current_step: step,
      frame: "connect your bank-saltedge",
    };
    // console.warn(values)
    await AuthPost("loan/update-step", values, "onboarding").then((res) => {
      if (res.statusCode == 200) {
        dispatch({
          type: "SET_STEP",
          payload: isCosignorFlow
            ? res.stepDt[0].active_cosignor_step
            : res.stepDt[0].step,
        });
        console.log(res);
        // setOpenmodal(false);
        // setNextStep(res["stepDt"][0]?.step);
        //add your navigate to login page thing if asked
                if(goNextStep){
          redirectFrame(res["stepDt"][0]?.step);
        }else{
          checkbank("vialink");

}
      } else {
        console.log(res.message);
      }
    });
  };

  const redirectFrame = (nextStep) => {
    console.log("step", nextStep);
    dispatch({
      type: "SET_STEP",
      payload: nextStep,
    });

    const nextFrame = frames.filter((el) => el.step === currentStep + 1);
    dispatch({
      type: "SET_CURRENT_FRAME",
      payload: nextFrame[0]?.name,
    });
    checkbank("savedata");
  };

  const closeFrame = () => {
    if(cancelsend==true){
      return;
      }
    // console.log("ref----->",elem.current.closeFrame)
    var conget = sessionStorage.getItem("connectionid");
    const dupframe = document.getElementById("dupeframe");
    if (dupframe != null) {
      dupframe.remove();
    }
    if (conget != null) {
      createConn(conget);
    }
  };
  return connectionid ? (
    <div className="frameshadow p-0">
      <div className="mainTitle">Bank Connected</div>
      <div className="p-2 textAlignCenter">
      <button className="MainButton btn_Primary" onClick={closeFrame}>
      Continue
      </button>
      </div>
    </div>
  ) : (
    <>
    {/* <div className="frameshadow p-0">
      <div className="mainTitle">Add Bank Details</div>
     <p className="p-2"> Please save your bank details to continue</p>
      <div className="mb50px p-2">
      <Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
        <div
          className="p10px flexEnd"
          style={{
            display: "flex",
            
            alignItems: "center",
            height: "100px",
          }}
        >
          <div>
          {skip && <SkipButton />}
          </div>

         <div> <button
            type="button"
            className="btn btn-success btn-user-control"
            onClick={send}
          >
            Connect Your Bank
          </button></div>
        </div>
      
      </div>
     
    </div> */}


<div>
<Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
<div className="displayFlex ">
  { !skip ? "" : <SkipButton />}
  <Button
    type={"submit"}
    value={"Click to link your Bank "}
    handleClick={() => {
      send();
    }}
  />
</div>
</div>
</>
  );
}

export default Saltedge;
