import React, { useEffect } from 'react';
import Banklookup from '../bank-lookup/bank-lookup';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthGet, Get, Post } from '../../common_var/httpService';
import { useStateValue } from '../../Context/StateProvider';
import toast from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';

function BankLookupContainer() {
  const [{ onboardConditions }, dispatch] = useStateValue();
  const { loanid, token } = useParams();
  const navigate = useNavigate();
  sessionStorage.setItem('loan_id', loanid);

  const checkToken = async () => {
    try {
      const resp = await Get(
        `auth/verify-bank-connect/${loanid}/${token}`,
        'borrower'
      );
      if (resp.statusCode === 200) {
        sessionStorage.setItem('borrower_token', resp.data.token);
        sessionStorage.setItem('user_id', resp.data.userId);
        getWorkflow();
      } else {
        toast.error('Invalid Token');
        if(process.env.REACT_APP_ISLOCALENV === "true"){
          window.open(`${process.env.REACT_APP_UI_URL}/borrower/login`, '_self');
      }else{
        let link;
          const links = window.location.hostname  
          console.log('links::: ', links);
          let val =links.split('-') 
          console.log('val::: ', val);
          let val2 =val[1].split('.')
          console.log('val2::: ', val2);
          let domainName  = val2[0]
          console.log('domainName::: ', domainName);
          link =`https://${domainName}.${process.env.REACT_APP_DOMAIN}`
          console.log('HOSTED_LINK', link);
          window.open(link ,'_self');

      }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkflow = async () => {
    try {
      const resp = await AuthGet(`api/getWorkFlow/${loanid}`, 'admin');
      if (resp.status === 200) {
        dispatch({
          type: 'SET_ONBOARD_CONDITIONS',
          payload: { ...resp.onboardConditions },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div>
      <Toast />
      {Object.keys(onboardConditions).length ? (
        <div>
          <div className="mb_40">
            <h2 className="heading30 mb_8 textAlignCenter">
              Your Loan Application
            </h2>
            <p className="paragraphText fontStyle_italic textAlignCenter">
              Please fill in the following sections completely & accurately to
              complete your loan application
            </p>
          </div>
          <div className="container plr100">
            <div className="stepsection">
              <section className="verticalStepbar">
                <div className="verticalStepbarItem ready">
                  <div className="Stepheader">
                    <div>
                      <h2>Bank Lookup</h2>
                      <p className="fontStyle_italic">
                        Connect securely to your Bank for us to generate the
                        best offer for you.
                      </p>
                    </div>
                  </div>
                  <div className="stepbody">
                    <div className="line"></div>
                    <div className="stepContent">
                      {Object.keys(onboardConditions).length ? (
                        <Banklookup step={6} skip={false} goNextStep={false} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default BankLookupContainer;
