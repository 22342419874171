export const initialState = {
  formData: {},
  errors: {},
  authData: {},
  formSubmitted: false,
  cosignerCheckbox: false,
  showToast: false,
  toastMessage: "",
  toastType: "",
  offers: [],
  loanId: "",
  globalStep: null,
  currentStep: null,
  coborrower_check: false,
  signature: null,
  selectLoanPopup: false,
  emailPhonePopup: false,
  frameId: null,
  layoutId: null,
  integrationsStatus: {
    identityVerification: {
      instnt: { isVerified: null, isWaiting: false, activate: false },
      idology: { isVerified: null, isWaiting: false, activate: false },
      metamap: { isVerified: null, isWaiting: false, activate: false },
      persona: { isVerified: null, isWaiting: false, activate: false },
      shuftipro: { isVerified: null, isWaiting: false, activate: false },
    },
    employmentVerification: {
      argyle: { isVerified: null, isWaiting: false, activate: false },
    },
    bankVerification: {
      plaid: { isVerified: null, isWaiting: false, activate: false },
      flinks: { isVerified: null, isWaiting: false, activate: false },
      dapi: { isVerified: null, isWaiting: false, activate: false },
      saltedge:{ isVerified:null, isWaiting:false, activate: false }
    },
    creditPull: {
      transUnion: { isVerified: null, isWaiting: false, activate: false },
      equifax: { isVerified: null, isWaiting: false, activate: false },
      experian: { isVerified: null, isWaiting: false, activate: false },
      crs: { isVerified: null, isWaiting: false, activate: false },
    },
  },
  onboardConditions: {},
  currentFrame: null,
  frames: [],
  isCosignorFlow: false,
  framesLength: 0,
  isFullPreview: false,
  currency: "",
  coborrower: false,
  selectedFormGroupOption: [],
  requiredFields: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUES":
      let data = action.payload;
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };

    case "SET_AUTH":
      let authdata = action.payload;
      return {
        ...state,
        authData: { ...state.authData, ...authdata },
      };

    case "SET_ERRORS":
      let err = action.payload;
      return {
        ...state,
        errors: { ...state.errors, ...err },
      };

    case "REMOVE_ERROR":
      // console.log("REMOVE_ERROR --->", action.payload);
      delete state.errors[action.payload];
      return {
        ...state,
      };

    case 'CLEAR_VALUES':
      if(!state.isFullPreview) {
        return {
          ...state,
          formData: {},
        };  
      }
      
    case 'FORM_SUBMIT':
      return {
        ...state,
        formSubmitted: action.payload,
      };

    case "SET_COSIGNER":
      return {
        ...state,
        cosignerCheckbox: action.payload,
      };
    case "TOAST":
      return {
        ...state,
        showToast: true,
        toastMessage: action.toastMessage,
        toastType: action.toastType,
      };

    case "LOAN_ID":
      return {
        ...state,
        loanId: action.payload,
      };

    case "SET_OFFERS":
      return {
        ...state,
        offers: action.payload,
      };
    case "SET_SIGN":
      return {
        ...state,
        signature: action.payload,
      };

    case "SET_STEP":
      return {
        ...state,
        globalStep: action.payload,
        currentStep: action.payload,
      };

    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };

    case "SET_LOANAMOUNT_POPUP":
      return {
        ...state,
        selectLoanPopup: action.payload,
      };

    case "SET_EMAILPHONE_POPUP":
      return {
        ...state,
        emailPhonePopup: action.payload,
      };

    case "SET_FRAMEID":
      return {
        ...state,
        frameId: action.payload,
      };

    case "SET_LAYOUTID":
      return {
        ...state,
        layoutId: action.payload,
      };

    case "CHANGE_SERVICE_STATUS":
      return {
        ...state,
        integrationsStatus: {
          ...state.integrationsStatus,
          ...action.payload,
        },
      };

    case "SET_ONBOARD_CONDITIONS":
      return {
        ...state,
        onboardConditions: action.payload,
      };

    case "SET_CURRENT_FRAME":
      return {
        ...state,
        currentFrame: action.payload,
      };

    case "SET_FRAMES":
      return {
        ...state,
        frames: action.payload,
      };

    case "SET_COSIGNOR_FLOW":
      return {
        ...state,
        cosignerCheckbox: action.payload,
        isCosignorFlow: action.payload,
      };

    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };
    case "SET_COBORROWER_CHECK":
      return {
        ...state,
        coborrower_check: action.payload,
      };

    case "SET_FRAMES_LENGTH":
      return {
        ...state,
        framesLength: action.payload,
      };

    case "SET_FULLVIEW_PREVIEW":
      return {
        ...state,
        isFullPreview: action.payload,
      };

    case "SET_FULLVIEW_FORMDATA":
      const s = state.formData;
      const d = action.payload;
      return {
        ...state,
        formData: { ...s, ...d },
      };

    case "SET_COBORROWER":
      return {
        ...state,
        coborrower: action.payload,
      };

    case "SET_SELECTED_FORMGROUP_OPTION":
      return {
        ...state,
        selectedFormGroupOption: action.payload,
      };

    case "SET_REQUIRED_FIELDS":
      return {
        ...state,
        requiredFields: [...state.requiredFields, action.payload],
      }

    case "RESET_REQUIRED_FIELDS":
      return {
        ...state,
        requiredFields: []
      }

    default:
      return state;
  }
};
