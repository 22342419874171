import HttpHandler from "./httpHandler";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getDetails,
} from "react-google-places-autocomplete";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import { useParams } from "react-router-dom";
// let token = (sessionStorage.getItem("userData"));
// let optionalHeaders = {
//   Authorization: token ? `Bearer ${token?.token}` : "",
// };

export const geturl = (url, key) => {
  if (key == "admin") {
    return `${process.env.REACT_APP_ADMIN_API_URL}/${url}`;
  } else if (key == "borrower") {
    return `${process.env.REACT_APP_BORROWER_API_URL}/${url}`;
  } else if (key == "onboarding") {
    return `${process.env.REACT_APP_ADMIN_API_URL}/${url}`;
  }
};

export const AuthPost = async (url, body, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
    if(!token){
      token=sessionStorage.getItem("borrower_token")
    }
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "onboarding") {
    token = sessionStorage.getItem("borrower_token");
  }
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  let activeUrl = geturl(url, key);
  let data = await HttpHandler.post(activeUrl, body, optionalHeaders);
  console.log(data);
  return data;
};

export const AuthPatch = async (url, body, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  }else if (key == "onboarding") {
    token = sessionStorage.getItem("borrower_token");
  }
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  let activeUrl = geturl(url, key);
  let data = await HttpHandler.patch(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthGet = async (url, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "onboarding") {
    token = sessionStorage.getItem("borrower_token");
  }

  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  let activeUrl = geturl(url, key);
  let data = await HttpHandler.get(activeUrl, "", optionalHeaders);
  return data;
};

export const Get = async (url, key) => {
  let activeUrl = geturl(url, key);
  let data = await HttpHandler.get(activeUrl, "", {});
  return data;
};

export const Post = async (url, body, key) => {
  let activeUrl = geturl(url, key);
  console.log(activeUrl);
  let data = await HttpHandler.post(activeUrl, body, {});
  return data;
};

export const AuthPut = async (url, body, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  }else if (key == "onboarding") {
    token = sessionStorage.getItem("borrower_token");
  }
  let UserId = sessionStorage.getItem("userId");
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  let activeUrl = geturl(url, key);
  let data = await HttpHandler.put(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthFile = async (url, body, key, method = "post") => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "onboarding") {
    token = sessionStorage.getItem("borrower_token");
  }

  let activeUrl = geturl(url, key);
  if (method === "post") {
    let data = await HttpHandler.post(activeUrl, body, {
      "content-type": "multipart/form-data",
      Authorization: token ? `Bearer ${token}` : "",
    });
    return data;
  } else if (method === "patch") {
    let data = await HttpHandler.patch(activeUrl, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return data;
  }
};

export const GooglePlaces = (e) => {
  return new Promise((resolve, reject) => {
    geocodeByAddress(e.label)
      .then((results) => {
        getLatLng(results[0])
          .then((res) => {
            fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.lat},${res.lng}&result_type=postal_code&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            )
              .then((response) => response.json())
              .then((data) => {
                let address_length =
                  data?.results[0]?.address_components.length;
                let datas = {
                  Zipcode: data?.results[0]?.address_components[0]?.long_name,
                  Address: e.label,
                  State:
                    data?.results[0]?.address_components[address_length - 2]
                      ?.long_name,
                  City: data?.results[0]?.address_components[address_length - 3]
                    ?.long_name,
                };
                resolve(datas);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};



//token function 

export const user_Id = (token) => {
  return JSON.parse(atob(token.split(".")[1])).user_id 
} 
