import Persona from 'persona';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';
import cr_loader from "../../assets/cr_loader.gif";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";

export const PersonaInt = ({ save, fId }) => {

    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleModalClose = (() => {
        setShow(false);
        if (fId) {
            save(fId);
        } else {
            save();
        }
        save();
    });

    return (
        <>
            <Modal show={show} className="getstartedPopup personaPopup">
                <Modal.Header>
                    <button className="emptyButton align_right" onClick={handleModalClose}>
                        <img src={CirclecloseIcon} />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {loading ? 
                        <div className="cr_loader ">
                            <img src={cr_loader} alt="Loading..." />
                        </div>
                    : ""
                    }
                    <Persona.Inquiry
                        templateId={process.env.REACT_APP_PERSONA_TEMP_ID}
                        environmentId={process.env.REACT_APP_PERSONA_ENV_ID}
                        onLoad={() => { 
                            console.log('Loaded inline');
                            setLoading(true);
                        }}
                        onReady={() => { 
                            console.log('ready');
                            setLoading(false);
                        }}
                        onComplete={({ inquiryId, status, fields }) => {
                            // Inquiry completed. Optionally tell your server about it.
                            handleModalClose();
                            console.log("Status and fields::", status + ":-:" + fields);
                            console.log(`Sending finished inquiry ${inquiryId} to backend`);
                        }}
                        onCancel={({ inquiryId, sessionToken }) => handleModalClose()}
                        onError={() => handleModalClose()}
                    />
                </Modal.Body>
            </Modal>
        </>
        
    );
};