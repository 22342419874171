import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Text from "../../Components/Text/Text";
import Email from "../../Components/Email/Email";
import Phone from "../../Components/Phone/Phone";
import NoOffers from "../../Pages/NoOffers/NoOffers";

const Offers = ({ step }) => {
  const [{ offers, formData, frames, currentStep, currency, isCosignorFlow,coborrower_check }, dispatch] = useStateValue();
  const { loanid } = useParams();
  const params = useLocation();
  const cosignor = params.pathname.split("/")[1];
  const [offErr, setOffErr] = useState(false);
  const [nooffers, setNooffers] = useState(false);
  const [showCoborrowerPopup, setShowCoborrowerPopup] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch({
      type: 'CLEAR_VALUES'
    })
  },[])

  useEffect(() => {
    isCosignorFlow === false && getCosignorInfo();
  }, [showCoborrowerPopup]);

  const getCosignorInfo = async () => {
    try {
      const resp = await AuthGet(
        `loan/get-cosignor-basicinfo/${loanid}`,
        "onboarding"
      );
      if (resp.statusCode === 200) {
        const { firstname, lastname, email, phone, middle_initial } =
          resp?.data[0];
        formData.coSignor_firstname = firstname;
        formData.coSignor_lastname = lastname;
        formData.coSignor_middle_initial = middle_initial;
        formData.coSignor_email = email;
        formData.coSignor_phone = phone;
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getSelectedOffer = async () => {
    try {
      let resp = await AuthGet(`loan/fetch-chosenOffer/${loanid}`, "onboarding");
      console.log("@#$#$%#$%#$#$%",resp.data)
      dispatch({
        type: "SET_VALUES",
        payload: { offer_id: resp?.data?.id , loanamount: resp?.data?.financialamount},
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSelectedOffer();
    if (offers.length === 0) {
      console.log("ERR");
      getSystemGenerateOffers();
    }
  }, []);

  useEffect(() => {
    if (formData.loanamount && formData.offer_id) {
      setOffErr(false);
    }
  }, [formData?.loanamount, formData?.offer_id]);

  const getSystemGenerateOffers = async () => {
    let res = await AuthGet(`loan/originate-offer/${step}/${loanid}`, "onboarding");
    console.log(res);
    if (res["statusCode"] == 200 ) {
      let data = res["data"];
      console.log("Offers data:::", data);
      dispatch({
        type: "SET_COBORROWER_CHECK",
        payload: res.step[0].active_cosignor_step > 0 ? true : false,
      });
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });
      }else{
        setNooffers(true)
      }
    } else {
      
      console.log("Invalid");
    }
  };

  const handleSelect = async (offer_id, loanAmount) => {
    console.log(offer_id);
    let data = {
      offer_id: offer_id,
      loanamount: loanAmount,
    };
    dispatch({
      type: "SET_VALUES",
      payload: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.loanamount && formData.offer_id) {
      setOffErr(false);
    } else {
      setOffErr(true);
    }

    try {
      if (formData.loanamount && formData.offer_id) {
        formData.step = step;
        console.log("OFFERS_FORMDATA", formData);
        let res = await AuthPost(
          `loan/store-suitable-Offer/${loanid}`,
          formData,
          "onboarding"
        );
        if (res.statusCode === 200) {
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
            ? res.data[0].active_cosignor_step
            : res.data[0].step,
          });
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: isCosignorFlow
            ? res.data[0].active_cosignor_step
            : res.data[0].step,
          });

          const nextFrame = frames.filter(el => el.step === currentStep + 1);
          dispatch({
            type: 'SET_CURRENT_FRAME',
            payload: nextFrame[0]?.name,
          });
        } else {
          // console.warn('===========>',res)
          toast.error(res.message[0]);
        }
      } else {
        toast.error("Please select an offer");
        setOffErr(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseCoBorrowerModal = () => {
    setShowCoborrowerPopup(false);
    formData.coSignor_firstname = "";
    formData.coSignor_lastname = "";
    formData.coSignor_middle_initial = "";
    formData.coSignor_email = "";
    formData.coSignor_phone = "";
    dispatch({
      type: "CLEAR_ERRORS",
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let val = e.target.value;

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleCosignor = async () => {
    try {
      const bodyData = {
        loan_id: loanid,
        cosignor_firstname: formData.coSignor_firstname,
        cosignor_lastname: formData.coSignor_lastname,
        cosignor_middle_initials: formData.coSignor_middle_initial?.trim(),
        cosignor_email: formData.coSignor_email,
        cosignor_phone: formData.coSignor_phone,
        step,
        loanid
      };
      const resp = await AuthPost(
        `loan/save-cosignor-basicinfo`,
        bodyData,
        "onboarding"
      );

      console.log("RES", resp);
      if (resp.statusCode === 200) {
        setShowCoborrowerPopup(false);
        dispatch({
          type: "SET_STEP",
          payload: isCosignorFlow ? resp?.active_cosignor_step : resp?.step,
        });
        dispatch({
          type: "SET_COBORROWER",
          payload: true,
        });
        navigate("/thankyou");
      } else if (resp.statusCode === 400) {
        toast.error(resp.message);
        return;
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  console.log("Offers::", offers);
  let url = window.location.href;

  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;

  const handleRedirectHome = () =>{
    if(!isLocalhost)
    {
      window.open(
        `${url.split("//")[0]}//${url.split("-")[1].split(".")[0]}.${
          process.env.REACT_APP_DOMAIN
        }/`,
        "_self"
      )
    } else{

      window.open(
        `${process.env.REACT_APP_UI_URL}`,
        "_self"
      );
    }  
    sessionStorage.clear();


  }

  return (
    <>
      {offers?.length > 0 && !nooffers?
        <div>
          <div className="row mb_24">
            {offers.map((ele, index) => (
              <div
                className="col-md-3"
                key={index}
                onClick={() => handleSelect(ele.id, ele.financialamount)}
              >
                <label
                  htmlFor={ele.id}
                  className="offerSection"
                  style={offErr ? { border: "1px solid red" } : null}
                >
                  <div className="displayFlex spaceBetween mb_16 gap6">
                    <div className="word_breakall">
                      <p
                        name="offers"
                        className="fs16px fontWeight600 loanamt_text mb_4"
                      >
                        Loan Amt - {currency}{ele.financialamount}
                      </p>

                      <p name="offers" className="fs14px fontWeight500 ">
                        {" "}
                        {currency}{ele.monthlyamount}/ mo
                      </p>
                    </div>
                    <div className="radioInput">
                      <input
                        type="radio"
                        name="offers"
                        id={ele.id}
                        checked={formData["offer_id"] === ele.id ? true : false}
                      />
                      <label htmlFor={ele.id}></label>
                    </div>
                  </div>

                  <div className="displayFlex AlignItemCenter spaceBetween">
                    <div className="word_breakall centerline_after">
                      <p name="offers" className="fs14px fontWeight500">
                        <span className="harbourFogClr">Tenor </span>{" "}
                        {ele.duration}
                      </p>
                    </div>
                    {/* <div className="word_breakall centerline_after">
                  </div> */}
                    <div className="word_breakall">
                      <p name="offers" className="fs14px fontWeight500">
                        APR %{ele.interestrate}
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>

          <Button type="submit" value="Continue" handleClick={handleSubmit} />
        </div>
        : nooffers?
        (<div>
          {coborrower_check ? <NoOffers params={params}/> :  <>
          <div>
            Unfortunately, you don't any have available offers.
            Would you like to add a coborrower?    
          </div>
          <div className="displayFlex flexEnd mt_20">
            {isCosignorFlow === false && (
              <Button
                type="button"
                value="Add co-borrower"
                handleClick={() => setShowCoborrowerPopup(true)}
              />
            )}
            <Button
                type="button"
                value="Go to Home Page"
                handleClick={() =>  handleRedirectHome() 
                }
              />
          </div>
          <div>
            <Modal
              show={showCoborrowerPopup}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={handleCloseCoBorrowerModal}
            >
              <Modal.Header closeButton>Add Co-Borrower</Modal.Header>
              <Modal.Body>
                <div className="row">
                
                    <Text
                      labelName={"first name"}
                      name={"coSignor_firstname"}
                      placeholder={"Cosigner First Name"}
                      trim={true}
                      required={true}
                      handleChange={handleChange}
                    />
                    <Text
                      name={"coSignor_middle_initial"}
                      labelName={"Middle Initials"}
                      placeholder={"Cosinger Middle Initials"}
                      handleChange={handleChange}
                    />
                  
                
                    <Text
                      name={"coSignor_lastname"}
                      labelName={"Last Name"}
                      placeholder={"Cosinger Last Name"}
                      handleChange={handleChange}
                      required={true}
                      trim={true}
                    />
                    <Email
                      handleChange={handleChange}
                      labelName={"Email"}
                      placeholder={"Cosinger Email"}
                      name={"coSignor_email"}
                      required={true}
                    />
                  
                  <Phone 
                    international={true}
                    defaultCountry={"US"}
                    handleChange={handleChange}
                    labelName={"Phone"}
                    name={"coSignor_phone"}
                    placeholder={"Cosignor Phone"}
                    required={true}
                  />
                  <Button
                    type={"submit"}
                    value={"Submit"}
                    handleClick={handleCosignor}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div> </>  }
            
        </div>)
        :
        (<div>     
        </div>)
      }
    </>
  );
};

export default Offers;
