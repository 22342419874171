import React, { useEffect } from "react";
import thankyou from "../../assets/images/thankyou-banner.svg";
import { useStateValue } from "../../Context/StateProvider";

const Thankyou = () => {
  const [{ coborrower }, dispatch] = useStateValue();
  let url = window.location.href;

  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  console.log("ISLOCAL", isLocalhost);

  useEffect(() => {
    console.log("first,", process.env.REACT_APP_UI_URL);

    setTimeout(() => {
      if(coborrower) {
        !isLocalhost
        ? window.open(
            `${url.split("//")[0]}//${url.split("-")[1].split(".")[0]}.${
              process.env.REACT_APP_DOMAIN
            }/`,
            "_self"
          )
        : window.open(
            `${process.env.REACT_APP_UI_URL}/`,
            "_self"
          );
      }
      else {
        !isLocalhost
          ? window.open(
              `${url.split("//")[0]}//${url.split("-")[1].split(".")[0]}.${
                process.env.REACT_APP_DOMAIN
              }/borrower/login`,
              "_self"
            )
          : window.open(
              `${process.env.REACT_APP_UI_URL}/borrower/login`,
              "_self"
            );
      }
      sessionStorage.clear();
    }, 3000);
  }, []);

  return (
    <div className="thankyoublock">
      <img src={thankyou} />
      <div className="thankyoutext">
        Your application has been submitted, thank you!
      </div>
    </div>
  );
};

export default Thankyou;
