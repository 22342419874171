import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const NoOffers = () => {  
    const navigate = useNavigate()
    let link;
    if(process.env.REACT_APP_ISLOCALENV === "true"){
        link = process.env.REACT_APP_UI_URL
        console.log('LOCAL_LINK', link);
    }else{
        const links = window.location.hostname  
        console.log('links::: ', links);
        let val =links.split('-') 
        console.log('val::: ', val);
        let val2 =val[1].split('.')
        console.log('val2::: ', val2);
        let domainName  = val2[0]
        console.log('domainName::: ', domainName);
        link =`https://${domainName}.${process.env.REACT_APP_DOMAIN}`
        console.log('HOSTED_LINK', link);
    }
    useEffect(()=>{
        setTimeout(() => { window.open(link, "_self");}, 6000
        )   
    });
  return (
        <>
        <div>
            <p>
            No offers available please contact your lender. Thank you
            </p>
        </div>
            </> 
 )
}

export default NoOffers