import React, { useEffect } from "react";
import Button from "../../Components/Button/Button";
import Text from "../../Components/Text/Text";
import { useStateValue } from "../../Context/StateProvider";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useParams } from "react-router-dom";
import { hasNullValueInObject } from "../../common_var/util";

const FinancialDetails = ({ step }) => {
  const { loanid } = useParams();
  const [{ formData, errors, cosignerCheckbox, formSubmitted, currentStep, frames, isCosignorFlow, isFullPreview, currency, requiredFields }, dispatch] =
    useStateValue();

  let payroll_date = [];
  for (let i = 1; i <= 31; i++) {
    payroll_date.push({ key: i, value: i });
  }

  const handleChange = (e) => {
    const name = e.target.name;
    let val = e.target.value;
    
    if (name === "zipcode" || name === "coSignor_zipcode" || name === "coSignor_duration") {
      val = val.replace(/[^\d]/g, "");
    }

    if (
      name == "monthly_income" ||
      name == "monthly_mortgage" ||
      name == "additional_income" ||
      name == "coSignor_additional_income" ||
      name == "coSignor_annual_income" ||
      name == "coSignor_monthly_income" ||
      name == "coSignor_monthly_mortgage"
    ) {
      val = +val?.replace(/[^\d]/g, "");
    }

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const calcAnnualIncome = () => {
    if (formData.monthly_income == 0) {
      dispatch({
        type: "SET_VALUES",
        payload: { annual_income: "0" },
      });
    } else if (formData.monthly_income){
      dispatch({
        type: "SET_VALUES",
        payload :{annual_income : formData.monthly_income * 12}
      })
    } else if (!formData.monthly_income) {
      dispatch({
        type: "SET_VALUES",
        payload: { annual_income: "" },
      });
    }
    if (formData.coSignor_monthly_income) {
      dispatch({
        type: "SET_VALUES",
        payload: {
          coSignor_annual_income: formData.coSignor_monthly_income * 12,
        },
      });
    }else  if (formData.coSignor_monthly_income == 0) {
      dispatch({
        type: "SET_VALUES",
        payload: { coSignor_annual_income: "0" },
      });
    }
  };

  useEffect(() => {
    calcAnnualIncome();
  }, [
    formData.monthly_income,
    formData.annual_income,
    formData.coSignor_monthly_income,
  ]);

  const getDetails = async () => {
    try {
      let res = await AuthGet(`loan/fetch-financeInfo/${loanid}`, "onboarding");
      if (res.statusCode === 200) {
        dispatch({type: "CLEAR_VALUES"});
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({type: "RESET_REQUIRED_FIELDS"});
    dispatch({
      type: "CLEAR_ERRORS",
    });
    !isFullPreview && getDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    const f = formData;
    
    try {
      const borrowerBodyData = {
        additional_income: +f.additional_income,
        annual_income: +f.annual_income,
        monthly_income: +f.monthly_income,
        monthly_mortgage: +f.monthly_mortgage,
        isCosignor: cosignerCheckbox,
        loan_id: loanid,
        step: step
      };

      const isFilled = hasNullValueInObject({...formData }, requiredFields);
      if(!isFilled) {
        console.log('isFilled::: ', isFilled);
        return;
      }
      
      if (Object.keys(errors).length === 0 && formSubmitted) {
        const resp = await AuthPost("loan/update-finance-info", borrowerBodyData, "onboarding");
          if (resp.statusCode === 200) {
              dispatch({
                type: "CLEAR_VALUES",
              });
              dispatch({type: "RESET_REQUIRED_FIELDS"});
              dispatch({
                type: "FORM_SUBMIT",
                payload: false,
              });
              dispatch({
                type: "SET_STEP",
                payload: isCosignorFlow ? resp.data[0].active_cosignor_step : resp.data[0].step,
              });
              const nextFrame = frames.filter(el => el.step === currentStep + 1);
              dispatch({
                type: 'SET_CURRENT_FRAME',
                payload: nextFrame[0]?.name,
              });
            }
          }
 
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="row">
        <Text
          maxLen={9}
          labelName={`Monthly Gross Income ${currency} (Before-Tax)`}
          name="monthly_income"
          placeholder={"Monthly gross income"}
          handleChange={handleChange}
          required={true}
          readonly={isFullPreview ? true : false} 
        />
        <Text
          labelName={`Annual Income ${currency} (Automatically calculated)`}
          name="annual_income"
          placeholder={"Annual Income"}
          handleChange={handleChange}
          readonly={true}
        />
        <Text
          maxLen={9}
          labelName={`Additional Income ${currency} (if any)`}
          name="additional_income"
          placeholder={"Additional Income"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
        />
        <Text
          maxLen={9}
          labelName={`Monthly Mortgage Payment ${currency}`}
          name="monthly_mortgage"
          placeholder={"Monthly Mortgage"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
        />
        {!isFullPreview && <Button
              type={"submit"}
              value={"Continue"}
              handleClick={(e)=>{
                dispatch({
                  type: "FORM_SUBMIT",
                  payload: true,
                })
                handleSubmit(e)}}
            />}
      </form>
    </>
  );
};

export default FinancialDetails;
